<template>
  <div class="rankingList-listContent">
    <div class="grid-content bg-purple-dark"
         style="margin: 0 auto;width:100%">
      <el-tabs v-model="activeName"
               style="height:368px;overflow:hidden;overflow-y:scroll;"
               @tab-click="setData">
        <el-tab-pane name="first">
          <div slot="label"
               style="position: relative;">
            城市
            <span @click.stop="activeStatus === 0 ? activeStatus =1 : activeStatus = 0; getRankList(activeStatus)"
                  :class="{isActive: activeStatus === 0}"
                  class="parkLable_top" />
            <span @click.stop="activeStatus === 1 ? activeStatus = 0 : activeStatus = 1; getRankList(activeStatus)"
                  :class="{isActive: activeStatus === 1}"
                  class="parkLable_bottom" />
          </div>
          <el-row v-for="(item, index) in parking.slice(0,10)"
                  :key="index"
                  class="row">
            <el-col class="col">
              <span class="indexNumber">{{index+1}}</span>
              <span class="item areaName"
                    style="left:100px;width:130px;">{{item.areaName}}</span>
              <span class="item"
                    style="right:80px">{{item.evCarCount}}辆</span>
            </el-col>
          </el-row>
        </el-tab-pane>
        <el-tab-pane name="second">
          <div slot="label"
               style="position: relative;">
            区域
            <span @click.stop="activeStatus2 === 0 ? activeStatus2 =1 : activeStatus2 = 0; getRankList2(activeStatus)"
                  :class="{isActive: activeStatus2 === 0}"
                  class="parkLable_top"
                  style="right:-4px;" />
            <span @click.stop="activeStatus2 === 1 ? activeStatus2 = 0 : activeStatus2 = 1; getRankList2(activeStatus)"
                  :class="{isActive: activeStatus2 === 1}"
                  class="parkLable_bottom"
                  style="right:-4px;" />
          </div>
          <el-row v-for="(item, index) in parking.slice(0,10)"
                  :key="index"
                  class="row">
            <el-col class="col">
              <span class="indexNumber">{{index+1}}</span>
              <span class="item areaName"
                    style="left:100px;width:130px;">{{item.areaName}}</span>
              <span class="item"
                    style="right:80px">{{item.evCarCount}}辆</span>
            </el-col>
          </el-row>
        </el-tab-pane>
        <el-tab-pane name="third">
          <div slot="label"
               style="position: relative;">
               道路/车场
            <span @click.stop="activeStatus3 === 0 ? activeStatus3 =1 : activeStatus3 = 0; getRankList3(activeStatus)"
                  :class="{isActive: activeStatus3 === 0}"
                  class="parkLable_top" />
            <span @click.stop="activeStatus3 === 1 ? activeStatus3 = 0 : activeStatus3 = 1; getRankList3(activeStatus)"
                  :class="{isActive: activeStatus3 === 1}"
                  class="parkLable_bottom" />
          </div>
          <el-row v-for="(item, index) in parking.slice(0,10)"
                  :key="index"
                  class="row">
            <el-col class="col">
              <span class="indexNumber">{{index+1}}</span>
              <span class="item areaName"
                    style="left:100px;width:130px;">{{item.parkName}}</span>
              <span class="item"
                    style="right:80px">{{item.evCarCount}}辆</span>
            </el-col>
          </el-row>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>
<script>
import ranking1 from "@/assets/img/ranking1.png";
import ranking2 from "@/assets/img/ranking2.png";
import ranking3 from "@/assets/img/ranking3.png";
export default {
  props: {
    parking: {
      default: function () {
        // 街道
        return [];
      }
    },
    rankingNumber: {
      default: function () {
        return [];
      }
    },
    activeNameTwo: {
      default: function () {
        return [];
      }
    }
  },
  data() {
    return {
      Number: 0,
      activeName: "first",
      ranking1: ranking1,
      ranking2: ranking2,
      ranking3: ranking3,
      desc: "0",
      activeStatus: 0,
      activeStatus2: 0,
      activeStatus3: 0,
    };
  },
  watch: {
    parking: {
      handler(curVal, oldVal) {
      },
      immediate: true
    },
    rankingNumber: {
      handler(curVal, oldVal) {
        this.Number = curVal;
      },
      immediate: true
    },
    activeNameTwo: {
      handler(curVal, oldVal) {
        if (curVal === "first") {
          this.activeName = "first";
        } else {
          this.activeName = "first";
        }
      },
      immediate: true
    }
  },
  methods: {
    setData(tab, event) {
      if (this.Number === 2) {
        const num = 1;
        let desc = this.desc;
        if (tab.name == "first") {
          this.$emit(desc, 1);
        } else if (tab.name == "second") {
          this.$emit(desc, 2,);
        } else if (tab.name == "third") {
          this.$emit(desc, 3);
        }
      }
      let desc = this.activeStatus;
      if (tab.name == "first") {
        this.$emit('getranking', desc, 3);
      } else if (tab.name == "second") {
        this.$emit('getranking', desc, 4,);
      } else if (tab.name == "third") {
        this.$emit('getranking', desc, -1);
      }
    },
    getRankList(desc) {
      this.activeName = "first";
      this.activeStatus2 = 0;
      this.activeStatus3 = 0;
      this.$emit('getranking', this.activeStatus, 3,);
    },
    getRankList2(desc) {
      this.activeName = "second";
      this.activeStatus1 = 0;
      this.activeStatus3 = 0;
      this.$emit('getranking', this.activeStatus2, 4,);
    },
    getRankList3(desc) {
      this.activeName = "third";
      this.activeStatus2 = 0;
      this.activeStatus1 = 0;
      this.$emit('getranking', this.activeStatus3, -1,);
    }
  }
};
// 						background:url(../../assets/img/ranking1.png);
</script>
<style lang="stylus" rel="stylesheet/stylus">
.rankingList-listContent {
  .el-tabs__nav {
    .el-tabs__item {
      width: 100px;
    }

    text-align: center;
  }
}
</style>
<style scoped lang="stylus" rel="stylesheet/stylus">
.rankingList-listContent {
  .grid-content {
    height: 369px;
    padding: 10px;

    .lj {
      float: right;
      margin-top: -30px;
    }

    .row:nth-last-child(1) {
      border-bottom: none;
      margin: 0 0;
    }

    .row {
      width: 100%;
      margin: 10px 0;
      border-bottom: 1px solid #EFE5F9;
      height: 40px;
      line-height: 18px;
      color: #333333;

      .col {
        width: 100%;

        .indexNumber {
          display: inline-block;
          width: 29px;
          height: 34px;
          line-height: 35px;
          text-align: center;
          margin-right: 10px;
          font-size: 15px;
        }

        .item {
          font-size: 15px;
          position: absolute;
          top: 8px;
        }

        .itemMoney {
          float: right;
          font-size: 15px;
          height: 34px;
          line-height: 35px;
        }

        .areaName {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          word-break: break-all;
        }
      }
    }
  }
}

.parkLable_top.isActive {
  border-bottom-color: #0f6eff !important;
}

.parkLable_bottom.isActive {
  border-top-color: #0f6eff !important;
}
</style>

