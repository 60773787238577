var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c("el-col", { attrs: { span: 12 } }, [
            _c(
              "div",
              {
                staticClass:
                  "grid-content bg-purple paddingT10 paddingLR20 graphShadow",
              },
              [
                _c(
                  "graphTitle",
                  { attrs: { title: "新能源车辆占比" } },
                  [
                    _c(
                      "el-tooltip",
                      {
                        attrs: {
                          placement: "top",
                          content:
                            "展示统计期间出现过的能源车辆数与非新能源车辆数之间的对比情况",
                        },
                      },
                      [_c("i", { staticClass: "el-icon-question my-icon" })]
                    ),
                  ],
                  1
                ),
                _c("div", {
                  staticClass: "pieEchats paddingB10",
                  attrs: { id: "newCarecharts" },
                }),
              ],
              1
            ),
          ]),
          _c("el-col", { attrs: { span: 12 } }, [
            _c(
              "div",
              {
                staticClass:
                  "grid-content bg-purple paddingT10 paddingLR20 graphShadow",
              },
              [
                _c(
                  "graphTitle",
                  { attrs: { title: "车型分类" } },
                  [
                    _c(
                      "el-tooltip",
                      {
                        attrs: {
                          placement: "top",
                          content:
                            "展示统计时间段内不同车型车之间数量的对比情况",
                        },
                      },
                      [_c("i", { staticClass: "el-icon-question my-icon" })]
                    ),
                  ],
                  1
                ),
                _c("div", {
                  staticClass: "pieEchats paddingB10",
                  attrs: { id: "carTypeEcharts" },
                }),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }